import RestaurantBlock from "../../RestaurantBlock/View/RestaurantBlock.view";
import CP_Pomaz from "../../../Resources/pomaz.png";
import CP_Szentendre from "../../../Resources/szentendre.png";
export interface IMainPageViewProps {}

const MainPageView: React.FC<IMainPageViewProps> = (props) => {
    return (
        <div className="main-page">
            {/* <header className="main-page-header">
                <HeaderBlock></HeaderBlock>
            </header> */}
            <footer className="main-page-footer">
                <RestaurantBlock
                    backgournd_url={CP_Pomaz}
                    link="https://pomaz.charliepeak.com"
                    className="align-right"
                />

                <RestaurantBlock
                    backgournd_url={CP_Szentendre}
                    link="https://szentendre.charliepeak.com"
                    className="align-left"
                />

                {/* <RestaurantBlock
                    name="Ünnepi Hidegtálrendelés"
                    address=""
                    phone="06 30 325 3256"
                    link="https://karacsony.mealbox.hu/"
                /> */}
            </footer>
        </div>
    );
};

export default MainPageView;
